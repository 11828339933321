.otp-container {
  display: flex;
  gap: 10px;
  justify-content: start;
}

.otp-input {
  width: 40px;
  height: 50px;
  text-align: center;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s;
}

.otp-input:focus {
  border-color: orange;
}
