.dropzone {
    min-height: auto;
    padding: 1.5rem 1.75rem;
    text-align: center;
    cursor: pointer;
    border: 1px dashed var(--bs-primary);
    background-color: var(--bs-primary-light);
    border-radius: 0.475rem !important;
}

.dropzone-drag {
    min-height: auto;
    padding: 1.5rem 1.75rem;
    text-align: center;
    cursor: pointer;
    border: 1px dashed var(--bs-info);
    background-color: var(--bs-info-light);
    border-radius: 0.475rem !important;
}

.dropzone-reject {
    min-height: auto;
    padding: 1.5rem 1.75rem;
    text-align: center;
    cursor: pointer;
    border: 1px dashed var(--bs-danger);
    background-color: var(--bs-danger-light);
    border-radius: 0.475rem !important;
}