.popupadd {
    display: flex;
    flex-wrap: wrap;
    background-color: white;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 10px;
    position: absolute;
    width: 22%;
    animation-name: fadeIn;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

.popupadd ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;    
}

.popupadd ul li {
    margin: 10px;
}

.popupadd ul a {
    color: black;
}

.popupadd ul a:hover {
    background-color: #cdecfc;
}

.titlelist {
    font-weight: bold;
    color: #b4b5c2;
    background-color: white;
}

.titlelist:hover {
    font-weight: bold;
    color: #b4b5c2;
    background-color: white !important;
}

.footer-form {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 20px;
    background-color: #ffff;
}

.accordion-button {
    font-weight: bold;
    font-size: 1.1rem;
}

.accordion-button:not(.collapsed) {
    font-weight: bold;
    color: black;
    background-color: white;
}

.navbar-personal-info {
    margin-bottom: 10px;
}

.card-personal-info-photo {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border: 2px solid #ecf1f3;
  border-radius: 10px;
  padding: 10px;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: 0;
}

.card-personal-info-photo img {
  object-position: center;
  object-fit: contain;
  width: 120px;
  height: 120px;
  border-radius: 100%;
  margin-bottom: 10px;
}


.card-personal-info-detail {
    padding: 0;
    margin: 0;
}

.float-right {
    text-align: right;
}

.data-jamaah {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 2px solid #ecf1f3;
    border-radius: 10px;
    padding: 20px;
    height: 100%;
}

.data-jamaah p:not(:first-child)
{
    margin-top: 18px;
}

.data-kesehatan {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 2px solid #ecf1f3;
    border-radius: 10px;
    padding: 20px;
}

.data-kesehatan p:not(:first-child)
{
    margin-top: 18px;
}

.data-haji-umroh {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 2px solid #ecf1f3;
    border-radius: 10px;
    padding: 20px;
}

.data-haji-umroh p:not(:first-child)
{
    margin-top: 18px;
}