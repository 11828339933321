.container-icon {
    margin-top: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .red-circle {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background-color: #ed2c33;
    position: absolute;
    border-color: aliceblue;
    top: 75%;
    left: 65%;
    transform: translate(-50%, -50%);
  }

  .yellow-circle {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background-color: #feb001;
    position: absolute;
    border-color: aliceblue;
    top: 75%;
    left: 65%;
    transform: translate(-50%, -50%);
  }

  .green-circle {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background-color: #04aa50;
    position: absolute;
    border-color: aliceblue;
    top: 75%;
    left: 65%;
    transform: translate(-50%, -50%);
  }
  
  .gray-circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #b4b4c3;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .icon-chat {
    color: white;
    font-size: 20px;
  }
  
