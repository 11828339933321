.redDots,
.redDots::before {
  content: " ";
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 0 auto;
  transition: all 1s;
  background-color: #EC2028;
}

.redDots::before {
  animation: mymove 1s infinite;
  position: absolute;
  background-color: #e37b7e;
}

