#root {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

#map-container {
  height: 100%;
  width: 100%;
  /* temporarily set the background color so we can tell where the map container is positioned */
  background-color: lightgrey;
}

.sidebar {
  background-color: rgb(35 55 75 / 90%);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  border-radius: 4px;
}

.reset-button {
  position: absolute;
  top: 50px;
  z-index: 1;
  left: 12px;
  padding: 4px 10px;
  border-radius: 10px;
  cursor: pointer;
}

.search-box {
  position: absolute;
  top: 10px;
  z-index: 1;
  left: 12px;
  padding: 4px 10px;
  border-radius: 10px;
  cursor: pointer;
  width: 250px;
}

.popup-device {
  width: 350px;
  height: 260px;
  background-color: white;
}
