.blank-container {
  background-color: #B5B5C3;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.auth-container {
  background-color: #FFFFFF;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: 1px solid #949497;
  border-radius: 15px;
  overflow: hidden;
}

.reset-password-container {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  padding: 40px;
  border: 1px solid #949497;
  border-radius: 15px;
  overflow: hidden;
}

.warning-message-container {
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  border : 2px solid #FFA800;
  border-left: 6px solid #FFA800;
}

.auth-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 20px;
}

.form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 380px;
  padding: 20px;
  border-radius: 20px;
}

/* otp */

.otp-container {
  display: flex;
  gap: 10px;
  justify-content: center;
  /* margin-bottom: 20px; */
}

.otp-input {
  width: 40px;
  height: 50px;
  text-align: center;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s;
}

.otp-input:focus {
  border-color: orange;
}

.timer {
  font-size: 16px;
  font-weight: bold;
  text-align: start;
}

.submit-btn {
  display: block;
  width: 100px;
  margin: 0 auto;
  padding: 10px 20px;
  background-color: #2196f3;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-btn:hover {
  background-color: #1e88e5;
}


@media only screen and (max-width: 1000px) {
  .auth-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    border-radius: 0px;
    background: url('../../../../../public/assets/img/header-login.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .auth-header {
    display: none;
  }

  .auth-form {
    min-width: 350px;
    max-width: 90%;
  }

  .form-container {
    background-color: #FFFFFF;
  }
}