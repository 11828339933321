.right-side-modal {
    width: 40%;
    position: fixed;
    top: 0;
    right: 0;
    left: 60%;
    bottom: 0;
    overflow-y: auto;
    transition: right 0.3s ease-out;
  }
  
  .right-side-modal.modal-open {
    right: 0;
  }
  
  .right-side-modal.modal-closed {
    right: -100%;
  }