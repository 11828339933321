.custom-toast-modal-with-button {
  position: fixed;
  top: 51rem;
  margin-right: 10px;
  width: auto;
  max-width: 300px;
  z-index: 1050;
}

.custom-toast-modal {
  position: fixed;
  top: 56rem;
  margin-right: 10px;
  width: auto;
  max-width: 300px;
  z-index: 1050;
}
