/* App.css */
.container {
    display: grid;
    gap: 10px;
    grid-auto-flow: column;
    /* grid-template-rows: 1fr 1fr 1fr;    */
  }
  
  .item {
    max-width: 265px;
  }

  .popup-filter {
    display: flex;
    flex-wrap: wrap;
    background-color: white;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 5px;
    position: fixed;
    width: 22%;
    animation-name: fadeIn;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    right: 50px;
    z-index: 999;
}