
@keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  
  .battery-blink {
    animation: blink 2s infinite;
  }
  