.custom-datepicker {
    font-size: 0.9em;
    font-family: 'Poppins';
    background-color: white;
}

.react-datepicker__month-container {
    float: left;
    width: 100%;
}

.react-datepicker__header {
    text-align: center;
    background-color: #ffffff;
    border-bottom: 1px solid #ffffff;
    border-top-left-radius: 0.3rem;
    padding: 8px 0;
    position: relative;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    margin-top: 0;
    color: #9b9b9b;
    font-weight: bold;
    font-size: 0.960rem;
    margin-bottom: 10px;
}

.react-datepicker__navigation {
    align-items: center;
    background: none;
    display: flex;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 6px;
    padding: 0;
    border: none;
    z-index: 1;
    height: 32px;
    width: 32px;
    text-indent: -999em;
    overflow: hidden;
}

.react-datepicker__day-names {
    margin-bottom: -8px;
    color: black;
    font-weight: bold;
}